import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { modalheaderstyles, modalbodystyles, modaltitlestyles } from './styles/ModalStyles';

const HowTo = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={() => props.onToggle()} contentClassName="howto-modal" centered={true} >

            <ModalHeader toggle={() => props.onToggle()} style={modalheaderstyles}><span style={modaltitlestyles}>How to Play?!</span></ModalHeader>

            <ModalBody style={modalbodystyles}>
                There is one right colour-combination. Can you find it? <br></br>Chose your colors by clicking the white squares and then the color you want to set for the field. When you're finished setting colors for all 4 fields, click "Check".
                    A right color in the right spot gives you a green dot. If a color is right but the position isn't, you get a yellow dot. Use the information to try again until you've found the code.
                        <br></br><br></br>
                <small>PS: No duplicate colors in the code!</small>
            </ModalBody>

        </Modal>
    );
};

export default HowTo;