import React, { Component } from 'react';
import styled from 'styled-components';

import {theme} from './styles/Theme';


const Codebox = styled.div`
  display: flex;
  width: 100%;
  height: 20rem;
  padding: ${theme.padding};
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  .dot {
    width: 10%;
    background-color: ${theme.offWhite};
    cursor: pointer;
    margin: 2rem;
    transition: .2s ease-in-out;

    @media screen and (max-width: 768px) {
      width: 18%;
      margin: 1rem;
    }
  }
  .dot:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
  }
  .dot:hover {
    box-shadow: ${theme.bs};
    transform: scale(1.05);
  }

  #yellow {
    background-color: ${theme.yellow};
  }
  #red {
    background-color: ${theme.red};
  }
  #blue {
    background-color: ${theme.blue};
  }
  #green {
    background-color: ${theme.green};
  }
  #purple {
    background-color: ${theme.purple};
  }
  #orange {
    background-color: ${theme.orange};
  }
  #teal {
    background-color: ${theme.teal};
  }
  #pink {
    background-color: ${theme.pink};
  }
  #grey {
    background-color: ${theme.grey}
  }
`;


class CodePanel extends Component {

  handleOpenColorSwitcher = (e) => {
    this.props.onOpenColorSwitcher(e);
  }

  render() {
      return (
          <Codebox>
              <div
                  className="dot dot1"
                  id={this.props.dot1}
                  onClick={e => this.handleOpenColorSwitcher(e)}
              ></div>
              <div
                  className="dot dot2"
                  id={this.props.dot2}
                  onClick={e => this.handleOpenColorSwitcher(e)}
              ></div>
              <div
                  className="dot dot3"
                  id={this.props.dot3}
                  onClick={e => this.handleOpenColorSwitcher(e)}
              ></div>
              <div
                  className="dot dot4"
                  id={this.props.dot4}
                  onClick={e => this.handleOpenColorSwitcher(e)}
              ></div>
          </Codebox>
      );
  }
}

export default CodePanel;