import React, { Component } from 'react';
import { theme } from '../components/styles/Theme';
import styled from 'styled-components';

const ColorSwitchPanel = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: ${theme.offWhite};
  box-shadow: ${theme.bs};
  top: 0;
  left: 0;
  padding: 20rem;
  display: ${props => props.display};
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 999;

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }


  .dot {
    width: 10%;
    background-color: ${theme.lightgrey};
    cursor: pointer;

    @media screen and (max-width: 768px) {
      width: 45%;
    }
  }
  .dot:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
  }

  .yellow {
    background-color: ${theme.yellow};
  }
  .red {
    background-color: ${theme.red};
  }
  .blue {
    background-color: ${theme.blue};
  }
  .green {
    background-color: ${theme.green};
  }
  .purple {
    background-color: ${theme.purple};
  }
  .orange {
    background-color: ${theme.orange};
  }
  .teal {
    background-color: ${theme.teal};
  }
  .pink {
    background-color: ${theme.pink};
  }
  .grey {
    background-color: ${theme.grey}
  }

  .close-button-line {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 5rem;
  right: 5rem;
  align-self: flex-end;
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    &::before, &::after {
      background: $blue;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;


class ColorSwitcher extends Component {

    onCloseColorSwitcher = () => {
        this.props.onColorSwitcherChange();
    }

    onSetColor = (e) => {
        this.props.onSetColor(e);
    }
    
    render() {
        return (
            <ColorSwitchPanel display={this.props.showColorSwitcher}>

                <div className="close-button-line">
                    <CloseButton
                        onClick={e => this.onCloseColorSwitcher(e)}
                    ></CloseButton>
                </div>

                <div
                    className="dot yellow"
                    disabled
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot red"
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot blue"
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot green"
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot pink"
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot purple"
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot orange"
                    onClick={e => this.onSetColor(e)}
                ></div>
                <div
                    className="dot teal"
                    onClick={e => this.onSetColor(e)}
                ></div>

            </ColorSwitchPanel>  
        );
    }
}

export default ColorSwitcher;