import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import reloadButton from '../static/reload.svg';
import exportButton from '../static/export.svg';
import howtoButton from '../static/howto.svg';

import HowTo from './HowToModal';
import Share from './ShareModal';

import { theme } from '../components/styles/Theme';

const Head = styled.header`
  display: flex;
  padding-top:3rem;
  width: 100%;

  .share-modal {
    padding: 4rem;
  }

  .share-modal .modal-content {
    padding: ${theme.padding} !important
  }

  .share-modal .modal-title {
    font-size: 3rem !important;
  }

  .header-container {
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    max-width: 120rem;
  }

  h1 {
    text-align: center;
    font-size: 6rem;
    font-family: 'Mr Dafoe', cursive;
    font-weight: 100;
    margin: 1.5rem;
    color: ${theme.offWhite};
    width: 100%;
    span {
      font-family: 'Noto Sans', sans-serif;
      font-size: 1.5rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 5rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 4rem;
    }

    a {
      color: ${theme.offWhite};
    }
    a:hover {
      text-decoration: none !important;
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    height: 5rem;

    img {
      width: 3rem;
      height:3rem;
      margin: 1rem;
      cursor: pointer;
    }

    img#reload:hover {
      animation-name: reloadButton;
      animation-duration: .5s;
      transform-origin: 50% 57%;
    }

    img#share:hover {
      animation-name: shareButton;
      animation-duration: .4s;
      animation-direction: alternate;

    }

    @keyframes reloadButton {
        from { transform: rotate(0); }
        to { transform: rotate(-360deg);}
    }

    @keyframes shareButton {
      0% { transform: translateY(0) }
      50% { transform: translateY(-1rem) }
      100% { transform: translateY()(0) }
    }
  }

`;

const Logo = styled.div`
  width: 15rem;
  display: flex;
  border: .75rem solid ${theme.offWhite};
  background: ${theme.offWhite};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  div {
    width: 25%;
    margin: .4rem;
  }
  div:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
  }
  .yellow {
    background-color: ${theme.yellow};
  }
  .red {
    background-color: ${theme.red};
  }
  .blue {
    background-color: ${theme.blue};
  }
  .green {
    background-color: ${theme.green};
  }
`;

class Header extends Component {

  state = {
    gameStarted: false,
    shareModal: false,
    howtoModal: false,
  }

  shareGame = () => {
    alert(`To continue this game save this ID: ${this.props.gameID}`);
  }

  toggle(modal) {
    this.setState({
      [modal]: !this.state[modal]
    });
  }


  render() {
      return (
          <Head>
              <div className="header-container">
                <Logo>
                    <div className="yellow"></div>
                    <div className="red"></div>
                    <div className="blue"></div>
                    <div className="green"></div>
                </Logo>

                <h1><Link to="/">Mighty Mind <span>1.0</span></Link></h1>

                {
                  this.props.gameStarted &&
                    <nav>
                      <img src={reloadButton} id="reload" alt="reload" onClick={() => this.props.onStartGame()} title="Start New Game"></img>
                      <img src={exportButton} id="share" alt="export" onClick={() => this.toggle("shareModal")} title="Continue later"></img>
                      <img src={howtoButton} id="share" alt="export" onClick={() => this.toggle("howtoModal")} title="Continue later"></img>
                    </nav>
                    
                }
                

              </div>
              
              <Share isOpen={this.state.shareModal} gameID={this.props.gameID} onToggle={() => this.toggle("shareModal")} />
              <HowTo isOpen={this.state.howtoModal} onToggle={() => this.toggle("howtoModal")} />
              
              
          </Head>
      );
  }
}

export default Header;

