import React, { Component } from 'react';
import styled from 'styled-components';
import { theme } from '../components/styles/Theme';

const StyledAttempt = styled.div`
  background-color: ${theme.offWhite};
  padding: ${theme.padding};
  width: 22%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1.5%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .dot {
    width: 18%;
    background-color: ${theme.lightgrey};
  }
  .dot:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
  }
  #yellow {
    background-color: ${theme.yellow};
  }
  #red {
    background-color: ${theme.red};
  }
  #blue {
    background-color: ${theme.blue};
  }
  #green {
    background-color: ${theme.green};
  }
  #purple {
    background-color: ${theme.purple};
  }
  #orange {
    background-color: ${theme.orange};
  }
  #teal {
    background-color: ${theme.teal};
  }
  #pink {
    background-color: ${theme.pink};
  }
  #grey {
    background-color: ${theme.grey}
  }

  .status-indicators {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  .status-dot {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin: 0 .2rem;
  }

  .right-color {
    background-color: #efdd1e;
  }
  .right-position {
    background-color: green;
  }

`;

class Attempt extends Component {
    render() {

        const attemptObject = this.props.data;
        
        return (
            
            <StyledAttempt key={Math.random()}>
                {
                    attemptObject.attempt.map(color => (
                        <div className="dot" id={color} key={Math.random()}></div>
                    ))
                }

                <div className="status-indicators">
                    {[...Array(attemptObject.rightColor)].map(() => <div className="status-dot right-color" key={Math.random()}></div>)}
                    {[...Array(attemptObject.rightPosition)].map(() => <div className="status-dot right-position" key={Math.random()}></div>)}
                </div>

            </StyledAttempt>
        );
    }
}

export default Attempt;