import React from 'react';
import styled from 'styled-components';

import { theme } from '../components/styles/Theme';

const SpinnerWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    left: 0;
    display: ${props => props.display};
`;

const Spinner = styled.div`
    position: absolute;
    background: ${theme.offWhite};
    width: 20rem;
    height: 5rem;
    left: 50%;
    top: 50%;
    margin-left: -10rem;
    margin-top: -2.5rem;

    ul {
        list-style: none;
        width: 100%;
        height: 100%;
        padding: 2rem;
        margin: 0;
        border: 6rem soldid ${theme.offWhite};
        box-shadow: ${theme.bs};
        li {
            float: left;
            width: 25%;
            height: 100%;
            transform: rotate(45deg);
        }
        #one {
            background: ${theme.yellow};
            animation-name: rotationone;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }
        #two {
            background: ${theme.red};
            animation-name: rotationtwo;
            animation-duration: 1s;
            animation-delay: .2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }
        #three {
            background: ${theme.blue};
            animation-name: rotationthree;
            animation-duration: 1s;
            animation-delay: .3s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }
        #four {
            background: ${theme.green};
            animation-name: rotationfour;
            animation-duration: 1s;
            animation-delay: .4s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }
    }

    @keyframes rotationone {
        from { transform: rotate(0); background-color: ${theme.yellow}}
        to { transform: rotate(180deg); background-color: ${theme.orange} }
    }
    @keyframes rotationtwo {
        from { transform: rotate(0); background-color: ${theme.red}}
        to { transform: rotate(180deg); background-color: ${theme.pink} }
    }
    @keyframes rotationthree {
        from { transform: rotate(0); background-color: ${theme.blue}}
        to { transform: rotate(180deg); background-color: ${theme.purple} }
    }
    @keyframes rotationfour {
        from { transform: rotate(0); background-color: ${theme.green}}
        to { transform: rotate(180deg); background-color: ${theme.teal} }
    }
`;

const LoadingSpinner = (props) => {
    return (
        <SpinnerWrapper display={props.showSpinner}>
            <Spinner>
                <ul>
                    <li id="one"></li>
                    <li id="two"></li>
                    <li id="three"></li>
                    <li id="four"></li>
                </ul>
            </Spinner>
        </SpinnerWrapper>
    );
};

export default LoadingSpinner;