import React from 'react';
import styled from 'styled-components';
import { theme } from '../components/styles/Theme';

const Congrats = styled.div`
    h1 {
        color: ${theme.offWhite};
        font-size: 5rem;
        margin: 5rem;

        @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 4rem;
        }
    }
`;

const Gratulations = (props) => {
    return (
        props.error
        ?
        <div></div>
        :
        <Congrats>
            <h1>Congrats, you win within {props.attempts} attempts!</h1>
        </Congrats>
    );
};

export default Gratulations;