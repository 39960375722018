import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { modalheaderstyles, modalbodystyles, modaltitlestyles } from './styles/ModalStyles';

const Share = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={() => props.onToggle()} contentClassName="share-modal" centered={true} >

            <ModalHeader toggle={() => props.onToggle()} style={modalheaderstyles}><span style={modaltitlestyles}>Come back later</span></ModalHeader>

            <ModalBody style={modalbodystyles}>
                Too hard? Share this game with a friend for help or try to continue later.
                  Just use click "Continue Game" on the landing page and paste this ID in the textbox: <br></br><strong>{props.gameID}</strong><br></br>
                Or share this Link: <strong><a href={`https://playmightymind.com/play/${props.gameID}`}>https://playmightymind.com/play/{props.gameID}</a></strong>

            </ModalBody>

        </Modal>
    );
};

export default Share;