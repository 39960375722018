import React, { Component } from 'react';
import styled from 'styled-components';

import Attempt from './Attempt'

const AttemptsWrapper = styled.div`
  width: 90%;
  margin: 3rem 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom:5rem;
`;


class Attempts extends Component {

    render() {
        return (
            <AttemptsWrapper>
                {
                    this.props.attempts
                    ? this.props.attempts.map(attemptObject => {
                            return (

                                <Attempt data={attemptObject} key={Math.random()} />

                            )
                        }
                    )
                    : <div></div>
                }

            </AttemptsWrapper>
        );
    }
}

export default Attempts;
