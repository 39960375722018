import firebase from 'firebase/app';
import 'firebase/firestore';
import { initFirestorter } from 'firestorter';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyA9co3mJmohrWaibcTqr43v79FPUAv6Eds",
    authDomain: "mighty-mind.firebaseapp.com",
    databaseURL: "https://mighty-mind.firebaseio.com",
    projectId: "mighty-mind",
    storageBucket: "mighty-mind.appspot.com",
    messagingSenderId: "736499764747"
};
firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = {timestampsInSnapshots: true};
firestore.settings(settings);

// Initialize `firestorter`
initFirestorter({ firebase: firebase });