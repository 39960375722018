import React, { Component } from 'react';
import styled from 'styled-components';
import { theme } from './styles/Theme';
import { Wrapper } from './styles/Wrapper';
import { withRouter } from "react-router-dom";

import '../firebase/firebase';
import { Collection, Document } from 'firestorter';
import { observer } from 'mobx-react'; 


import ColorSwitcher from './ColorSwitcher';
import CodePanel from './CodePanel';
import Attempts from './Attempts';
import LoadingSpinner from './LoadingSpinner';
import Header from './Header';
import Gratulations from './Gratulations';
import Fireworks from './Fireworks';
import Error from './Error';



const games = new Collection('games');
let gameData = '';


const CheckButton = styled.button`
  border: 0;
  background-color: ${theme.offWhite};
  color: ${theme.black};
  padding: 2rem 6rem;
  font-size: 3rem;
  margin-top: 3rem;
  @media screen and (max-width: 768px) {
      margin-top: 0;
  }
  font-weight: 800;
  box-shadow: ${theme.bs};
  cursor: pointer;
  :active {
    box-shadow: none;
    border: none;
  }
  :focus {
    outline: none;
  }
`;

const CodeWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Game = observer(class Game extends Component {

    state = {
        showColorSwitcher: "none",
        possibleColors: ['green', 'blue', 'red', 'orange', 'purple', 'yellow', 'pink', 'teal'],
        dotToSet: '',
        dots: {
            dot1: '',
            dot2: '',
            dot3: '',
            dot4: '',
        },
        attempts: [],
        showSpinner: 'none',
        gameStarted: true
    }

    createRandomColor = () => {

        // find a color
        const possibleColors = this.state.possibleColors;
        return possibleColors[Math.floor(Math.random() * possibleColors.length)];

    }

    createNewGame = async() => {

        this.setState({
            showSpinner: 'block'
        })

        const dotsArray = Object.keys(this.state.dots);
        const dotLength = dotsArray.length;

        // create empty array for the solution
        const solution = [];

        // run createRandomColor until we got 4 different colors in the solution array
        while (solution.length < dotLength) {
            let createdColor = this.createRandomColor();

            if (solution.indexOf(createdColor) !== -1) {
                createdColor = this.createRandomColor;
            } else {
                solution.push(createdColor)
            }
        }

        // add a new Document to the games Collection in Firebase and set the created solution
        const game = await games.add({
            solution
        })

        // push  the ID of the newly created game in Firebase to state
        this.setState({
            gameID: game.id,
            showSpinner: 'none'
        })

        this.props.history.push(`/play/${game.id}`);


    }

    resumeGame = async() => {

        await this.setState({
            gameID: this.props.match.params.id,
        })

        // Fetch the game with the game ID from firebase
        const doc = await new Document(`games/${this.state.gameID}`, 'off');
        await doc.fetch().then(({ data }) => {
            gameData = data;
        })

        if (!gameData.solution) {
            this.setState({
                error: 'Game not found'
            })
        }

        // initialize the game via state
        this.setState({
            attempts: gameData.attempts || [],
            dots: {
                dot1: gameData.attempts ? gameData.attempts[gameData.attempts.length - 1].attempt[0] : '',
                dot2: gameData.attempts ? gameData.attempts[gameData.attempts.length - 1].attempt[1] : '',
                dot3: gameData.attempts ? gameData.attempts[gameData.attempts.length - 1].attempt[2] : '',
                dot4: gameData.attempts ? gameData.attempts[gameData.attempts.length - 1].attempt[3] : ''
            },
            gameWon: gameData.gameWon,
        })
    }

    refresh = () => {
        if (this.props.match.params.id) {
            this.props.history.push('/play/');
        } else {
            this.startGame();
        }
    } 

    startGame = async() => {

        // display the loading spinner and reset state
        this.setState({
            dotToSet: '',
            dots: {
                dot1: '',
                dot2: '',
                dot3: '',
                dot4: '',
            },
            attempts: [],
            gameWon: false
        })

        // check if there is a game ID in the URL Params, if not, create a new game, if yes, resume the game
        if (this.props.match.params.id === '' || !this.props.match.params.id ) {
            // Set up a new game
            this.createNewGame();  
        } else {
            // Resume an existing game from gameID passed in the URL params
            this.resumeGame();
        }

    }

    componentWillMount() {
        this.startGame();
    }


    openColorSwitcher = (e) => {
        const dot = e.target.classList[1];

        this.setState({
            dotToSet: dot,
            showColorSwitcher: "flex",
        })
    }

    closeColorSwitcher = () => {
        this.setState({
            showColorSwitcher: "none"
        })
    }

    setColor = (e) => {
        const color = e.target.classList[1];
        const dots = this.state.dots;
        dots[this.state.dotToSet] = color;
        this.setState({
            dots: {
                ...dots
            }
        });
        this.closeColorSwitcher();
    }

    disabledUsedColors = () => {
        // put all used colors in the state in an array
        // disable the corresponding Buttons
    }

    checkCode = async() => {

        this.setState({
            showSpinner: 'block'
        })

        const doc = await new Document(`games/${this.state.gameID}`, 'off');
        await doc.fetch().then(({data}) => {
            gameData = data;
        })

        // create an Array from the solution object and save it and its length to Constants
        const solution = Object.values(gameData.solution);
        const solutionLength = solution.length;

        // create an array of the colors from the dots in state
        const attempt = Object.values(this.state.dots);

        let rightPosition = 0;
        let rightColor = 0;

        // Do the checking
        // the loop to check for matching positions and/or colors
        for (var i = 0; i < solutionLength; i++) {

            if (solution[i] === attempt[i]) {
                rightPosition++;
            } else if (solution.indexOf(attempt[i]) !== -1) {
                rightColor++;
            }
        }

        // if we got all right, we win
        if (rightPosition === solutionLength) {

            this.setState({
                gameWon: true
            })
            await doc.update({
                gameWon: true
            })
        }

        // get a copy of the attempts array from state
        const attempts = this.state.attempts;

        // TODO get a copy of the attempts array from firebase
        const attemptsFirebase = gameData.attempts || []; 

        // create a new attempt object
        const currentAttempt = {
            rightPosition,
            rightColor,
            attempt
        }

        // push the current attempt object to the copy
        attempts.unshift(currentAttempt);
        attemptsFirebase.unshift(currentAttempt);

        // push the altered attempts copy to state
        this.setState({
            attempts
        })
       
        // TODO push the altered attempts copy to firebase
        await doc.update({
            attempts: attemptsFirebase
        })

        this.setState({
            showSpinner: 'none'
        })

    }


    render() {
        return (
            <Wrapper>

                <Fireworks won={this.state.gameWon} />
                

                <Header gameStarted={this.state.gameStarted} gameID={this.state.gameID} onStartGame={() => this.refresh()} />
               

                <LoadingSpinner showSpinner={this.state.showSpinner} />
            
                <ColorSwitcher
                    showColorSwitcher={this.state.showColorSwitcher}
                    onColorSwitcherChange={() => this.closeColorSwitcher()}
                    onSetColor={(e) => this.setColor(e)}
                />

                <Error error={this.state.error} />
                
                {
                    ((this.state.gameWon === false || !this.state.gameWon) && (!this.state.error || this.state.error === ''))
                    ?
                    <CodeWrapper>
                        <CodePanel
                            dot1={this.state.dots.dot1}
                            dot2={this.state.dots.dot2}
                            dot3={this.state.dots.dot3}
                            dot4={this.state.dots.dot4}
                            onOpenColorSwitcher={(e) => this.openColorSwitcher(e)}
                        />
                        <CheckButton
                            onClick={() => this.checkCode()}
                        >CHECK!</CheckButton>
                    </CodeWrapper>
                    :
                    <Gratulations attempts={this.state.attempts.length} error={this.state.error} />
                }

                <Attempts
                    attempts={this.state.attempts}
                />
            </Wrapper>


        );
    }
})

export default withRouter(Game);