import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { theme } from './styles/Theme';
import Header from './Header';


const StartScreen = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    h2 {
        width: 100%;
        text-align: center;
        color: ${theme.offWhite};
        font-size: 6rem;
        margin: 5rem 0;
        text-transform: uppercase;
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-size: 4.5rem;
        }
    }
`;

const Box = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: ${theme.offWhite};
    width: 40%;
    justify-content: center;
    align-items: center;
    box-shadow: ${theme.bs};
    padding: ${theme.padding};

    @media screen and (max-width: 768px) {
        width: 90%;
        margin-bottom: 2rem;
    }

    .new-game-button {
        margin-bottom:1rem;
    }

    h3 {
        text-align: center;
        width: 100%;
        font-size: 3rem;
        margin: 0 0 2rem 0;
        font-weight: 600;
        color: ${theme.black};
    }
    p {
        color: ${theme.black};
        width: 100%;
        text-align: center;
    }

    a {
        width: 100%;
        margin: 1rem;
    }

    small {
        display:block;
        margin-top: 1.5rem;
    }

    input {
        font-size: 1.25rem;
        padding: 1rem;
        margin: 1rem;
        width: 80%;
    }
`;

const Button = styled.button`
    background: #284855;
    box-shadow: none;
    border: none;
    color: white;
    font-size: 2rem;
    width: 100%;
    padding: 1.5rem 2rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;

    :disabled {
        background-color: lightgrey;
        cursor: not-allowed;
    }
`;

class Welcome extends Component {

    state = {
        gameStarted: false
    }

    startGame = () => {
        this.setState({
            gameStarted: !this.state.gameStarted
        })
    }

    onIdChange = (e) => {
        this.setState({
            continueID: e.target.value
        })
    }

    render() {
        return (
            <StartScreen>

                <Header gameStarted={this.state.gameStarted} />

                <h2>Welcome!</h2>
                <Box>
                    <h3>How to Play?!</h3>
                    <p>
                        There is one right colour-combination. Can you find it? <br></br>Chose your colors and check your attempt. 
                        A right color in the right spot gives you a green dot. If a color is right but the position isn't, you get a yellow dot. Use the information to try again until you've nailed the code.
                        <br></br>
                        <small>PS: No duplicate colors in the code!</small>
                    </p>
                </Box>
                <Box>
                    <Link to="/play/" onClick={this.startGame}><Button className="new-game-button">Start New Game</Button></Link>
                    <p><strong>OR</strong></p>
                    <input type="text" onChange={(e) => this.onIdChange(e)} onClick={e => e.preventDefault} placeholder="Paste a Game ID, then click the Continue Button" />
                    <Link to={`/play/${this.state.continueID}` || "/"}><Button>Continue</Button></Link>
                </Box>
            </StartScreen>
        );
    }
};

export default Welcome;