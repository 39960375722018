import React from 'react';
import styled from 'styled-components';
import { theme } from '../components/styles/Theme';

const ErrorMessage = styled.div`
  padding: ${theme.padding};

  h1, h2 {
      text-align: center;
      color: white;
  }
`;

const Error = (props) => {
    return (
        props.error
        ?
        <ErrorMessage>
            <h1>Sorry, an Error occured: </h1>
            <h2>{props.error}</h2>
        </ErrorMessage>
        :
        <div></div>
    );
};

export default Error;