export const theme = {
    red: '#FF5252',
    green: '#4CAF50',
    purple: '#9C27B0',
    yellow: '#FFEB3B',
    pink: '#C2185B',
    blue: '#2196F3',
    orange: '#FF9800',
    teal: '#009688',
    black: '#393939',
    grey: '9E9E9E',
    lightgrey: '#E1E1E1',
    offWhite: '#fff9ff',
    maxWidth: '1000px',
    bs: '0 12px 24px 0 rgba(0,0,0,.09)',
    padding: '5rem'
}