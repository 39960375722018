import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import { Wrapper } from './components/styles/Wrapper';
import { GlobalStyle } from './components/styles/GlobalStyle';

import Welcome from './components/Welcome';
import Game from './components/Game';
import Footer from './components/Footer';

import 'firebase/firestore';

class App extends Component {
  
  render() {
    return (
      <Router>
        <Wrapper >
          <GlobalStyle />

          <Route path="/" exact component={Welcome} />
          <Route path="/play" exact component={Game} />
          <Route path="/play/:id" component={Game} />
          
          <Footer />

        </Wrapper>
      </Router>
    );
  }
}

export default App;
