import React from 'react';
import styled from 'styled-components';
import { theme } from '../components/styles/Theme';

const StyledFooter = styled.footer`
  /* background-color: ${theme.offWhite}; */
    padding: 2rem;
    text-align: center;
    width: 100%;
    margin-top: 3rem;
    small, a {
      color: white !important;
    }
`;

const Footer = () => {
    return (
        <StyledFooter>
            <small>built with ♥ and green tea by <a href="https://tomcodes.at" target="_blank" rel="noopener noreferrer">Tom</a> in <a href="https://www.wien.info/en/lifestyle-scene/most-livable-city" target="_blank" rel="noopener noreferrer">Vienna</a></small>
        </StyledFooter>
    );
};

export default Footer;